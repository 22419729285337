<template>
  <!-- pagina não utilizada ???? settings/create-->
  <div class="row justify-content-center">
    <div class="col-12">
      <h5 class="font-weight-light text-uppercase mb-4">Create user</h5>

      <div class="card bg-light border-white rounded py-4">
        <form v-on:submit.prevent="createUser">
          <div class="row mx-5 my-1">
            <div class="col">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  v-model="user.name"
                  id="name"
                  class="form-control"
                  :class="{ 'is-invalid': validationErrors['name'] }"
                  required
                />

                <div
                  class="invalid-feedback"
                  v-for="(error, index) in validationErrors['name']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-5 my-1">
            <div class="col">
              <div class="form-group">
                <label for="phone">Phone Number</label>
                <input
                  type="text"
                  v-model="user.phone"
                  id="phone"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label for="whatsapp_number">Whatsapp Number</label>
                <input
                  type="text"
                  v-model="user.whatsapp_number"
                  id="whatsapp_number"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div class="col">
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="email"
                  v-model="user.email"
                  id="email"
                  class="form-control"
                  :class="{ 'is-invalid': validationErrors['email'] }"
                  required
                />

                <div
                  class="invalid-feedback"
                  v-for="(error, index) in validationErrors['email']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div class="col" v-if="loggedInUser.profile == 'nobordist'">
              <div class="form-group">
                <label for="profile">Profile</label>
                <select
                  v-model="user.profile"
                  id="profile"
                  class="form-control"
                >
                  <option value="">Select</option>
                  <option value="nobordist">Nobordist</option>
                  <option value="carrier">Carrier</option>
                  <option value="seller">Seller</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div
              class="col"
              v-if="
                loggedInUser.profile == 'nobordist' && user.profile == 'seller'
              "
            >
              <div class="form-group">
                <label for="seller">Seller</label>
                <select
                  v-model="user.seller_id"
                  id="seller"
                  class="form-control"
                >
                  <option value="">Select</option>
                  <option
                    v-for="(seller, index) in sellers"
                    :key="index"
                    :value="seller.id"
                  >
                    {{ seller.name }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="col"
              v-if="
                loggedInUser.profile == 'nobordist' && user.profile == 'carrier'
              "
            >
              <div class="form-group">
                <label for="carrier">Carrier</label>
                <select
                  v-model="user.carrier_id"
                  id="carrier"
                  class="form-control"
                >
                  <option value="">Select</option>
                  <option
                    v-for="(carrier, index) in carriers"
                    :key="index"
                    :value="carrier.id"
                  >
                    {{ carrier.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div class="col">
              <div class="form-group">
                <label for="group">Group</label>
                <select
                  v-model="user.group_id"
                  id="group"
                  class="form-control"
                  :class="{ 'is-invalid': validationErrors['group'] }"
                >
                  <option value="">Select</option>
                  <option
                    v-for="(group, index) in groups"
                    :key="index"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>

                <div
                  class="invalid-feedback"
                  v-for="(error, index) in validationErrors['group']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div class="col">
              <div class="form-check-inline mt-1">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value=""
                    v-model="user.is_blocked"
                  />Blocked
                </label>
              </div>
            </div>
          </div>

          <div class="row mx-5 mt-3 d-flex justify-content-center">
            <button type="submit" class="mt-2 button-success float-center">
              Create User
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../services/UserService";
import UsersService from "../../services/UsersService";
import GroupService from "../../services/GroupService";
import SellerService from "../../services/SellerService";
import CarrierService from "../../services/CarrierService";
import NProgress from "nprogress";

const userService = new UserService();
const usersService = new UsersService();
const groupService = new GroupService();
const sellerService = new SellerService();
const carrierService = new CarrierService();

export default {
  name: "UserCreate",
  data: () => {
    return {
      user: {
        name: "",
        email: "",
        phone: "",
        whatsapp_number: "",
        password_confirmation: "",
        password: "",
        group_id: "",
        profile: "",
        is_blocked: false,
      },
      confirm_password: "",
      loggedInUser: {},
      groups: [],
      validationErrors: [],
      sellers: [],
      carriers: [],
    };
  },
  beforeMount() {
    this.loggedInUser = userService.getLoggedInUser();

    if (this.loggedInUser.profile == "seller") {
      this.user.profile = "seller";
    } else if (this.loggedInUser.profile == "seller") {
      this.user.profile = "carrier";
    } else if (this.loggedInUser.profile == "nobordist") {
      // get sellers
      sellerService.getSellers().then((response) => {
        this.sellers = response.data.data.elements;
      });

      // get carriers
      carrierService.getCarriers().then((response) => {
        this.carriers = response.data.data.elements;
      });
    }

    groupService.getGroups().then((response) => {
      this.groups = response.data.data;
    });
  },
  methods: {
    createUser() {
      usersService
        .setUser(this.user)
        .then(() => {
          this.$router.push("/users/list").then(() => {
            this.$helpers.toast("User created", "success");
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();

          window.scrollTo(0, 0);
        });
    },
  },
};
</script>
