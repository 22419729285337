<template>
  <LoadingPage v-if="loaded" />
  <div v-else class="row justify-content-center">
    <div class="col-12">
      <h5 class="font-weight-light text-uppercase mb-4">
        {{ $t("settingsEditPage.title") }}
      </h5>

      <div class="card bg-light border-white rounded py-4">
        <form v-on:submit.prevent="updateUser">
          <div class="row mx-5 my-1">
            <div class="col">
              <div class="form-group">
                <label for="name">{{ $t("name") }}</label>
                <input
                  type="text"
                  v-model="user.name"
                  id="name"
                  class="form-control"
                  :class="{ 'is-invalid': validationErrors['name'] }"
                  required
                />

                <div
                  class="invalid-feedback"
                  v-for="(error, index) in validationErrors['name']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
            </div>

            <div class="col">
              <label for="company">{{ $t("company") }}</label>
              <input
                type="text"
                id="company"
                class="form-control"
                :value="user.company_name"
                readonly
              />
            </div>
          </div>

          <div class="row mx-5 my-1">
            <div class="col">
              <div class="form-group">
                <label for="phone">{{ $t("phone") }}</label>
                <input
                  type="text"
                  v-model="user.phone"
                  id="phone"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <label for="whatsapp_number">{{ $t("whatsapp") }}</label>
                <input
                  type="text"
                  v-model="user.whatsapp_number"
                  id="whatsapp_number"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div class="col">
              <div class="form-group">
                <label for="email">{{ $t("email") }}</label>
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  :value="user.email"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div class="col" v-if="loggedInUser.profile == 'nobordist'">
              <div class="form-group">
                <label for="profile">{{ $t("profile") }}</label>
                <input
                  type="text"
                  id="profile"
                  class="form-control"
                  :value="user.profile"
                  readonly
                />
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div class="col">
              <div class="form-group">
                <label for="group">{{
                  $t("groupConfigurationPage.group")
                }}</label>
                <select
                  v-model="user.group_id"
                  id="group"
                  class="form-control"
                  :class="{ 'is-invalid': validationErrors['group'] }"
                >
                  <option value="">{{ $t("orderStatus.selectOption") }}</option>
                  <option
                    v-for="(group, index) in groups"
                    :key="index"
                    :value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>

                <div
                  class="invalid-feedback"
                  v-for="(error, index) in validationErrors['group']"
                  :key="index"
                >
                  {{ error }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mx-5 my-2">
            <div class="col">
              <div class="form-check-inline mt-1">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    value=""
                    v-model="user.is_blocked"
                  />{{ $t("blocked") }}
                </label>
              </div>
            </div>
          </div>

          <div class="row mx-5 mt-3 d-flex justify-content-center">
            <button type="submit" class="mt-2 button-primary float-center">
              {{ $t("update") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../../services/UserService";
import UsersService from "../../services/UsersService";
import GroupService from "../../services/GroupService";
import NProgress from "nprogress";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const userService = new UserService();
const usersService = new UsersService();
const groupService = new GroupService();

export default {
  name: "UsersEdit",
  components: { LoadingPage },
  data: () => {
    return {
      loaded: true,
      user: {
        name: "",
        email: "",
        phone: "",
        whatsapp_number: "",
        group_id: "",
        profile: "",
        is_blocked: false,
      },
      loggedInUser: {},
      groups: [],
      validationErrors: [],
    };
  },
  beforeMount() {
    this.loggedInUser = userService.getLoggedInUser();

    if (this.loggedInUser.profile == "seller") {
      this.user.profile = "seller";
    } else if (this.loggedInUser.profile == "seller") {
      this.user.profile = "carrier";
    }

    groupService.getGroups().then((response) => {
      this.groups = response.data.data;
      usersService.getUser(this.$route.params.id).then((response) => {
        this.user = response.data.data;
        this.loaded = false;
      });
    });
  },
  methods: {
    updateUser() {
      usersService
        .updateUser(this.$route.params.id, this.user)
        .then(() => {
          this.$router.push("/users/list").then(() => {
            this.$helpers.toast("User updated", "success");
          });
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }

          let errors = this.$helpers.extractErrorMessages(error);

          errors.forEach((message) => {
            this.$helpers.toast(message, "danger");
          });

          NProgress.done();

          window.scrollTo(0, 0);
        });
    },
  },
};
</script>
