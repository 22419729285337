<template>
  <LoadingPage v-if="loaded" />
  <div v-else class="row justify-content-center">
    <div class="col-12">
      <h5 class="font-weight-light text-uppercase mb-4">
        {{ $t("settingsViewPage.title") }}
        <span v-if="groups.filter((x) => x.id == user.group_id).length"
          >>
          <span class="badge badge-info text-uppercase ml-3">{{
            groups.filter((x) => x.id == user.group_id)[0].name
          }}</span></span
        >
        <span
          v-if="logged_in_user.profile === 'nobordist'"
          class="badge badge-info text-uppercase ml-3"
          >{{ user.profile }}</span
        >
        <span
          v-if="user.is_blocked"
          class="badge badge-danger text-uppercase ml-3"
          >{{ $t("settingsViewPage.title") }}</span
        >
        <span
          v-if="!user.is_blocked"
          class="badge badge-success text-uppercase ml-3"
          >{{ $t("settingsViewPage.title") }}</span
        >

        <button
          v-if="!user.is_blocked"
          class="button-danger float-right mb-2"
          v-on:click="blockUser"
          title="Block this user"
        >
          <i class="fas fa-chevron-circle-right mr-1"></i
          >{{ $t("settingsViewPage.title") }}
        </button>

        <button
          v-if="user.is_blocked"
          class="button-success float-right mb-2"
          v-on:click="unblockUser"
          title="Unblock this user"
        >
          <i class="fas fa-chevron-circle-right mr-1"></i
          >{{ $t("settingsViewPage.title") }}
        </button>
      </h5>

      <div class="card bg-light border-white rounded py-4">
        <div class="row mx-5 my-2">
          <div class="col">
            <h6>{{ $t("name") }}</h6>
            <div class="border rounded bg-white px-3 py-2">{{ user.name }}</div>
          </div>
          <div class="col">
            <h6>{{ $t("company") }}</h6>
            <div class="border rounded bg-white px-3 py-2">
              {{ user.company_name }}
            </div>
          </div>
        </div>

        <div class="row mx-5 my-2">
          <div class="col">
            <h6>{{ $t("phone") }}</h6>
            <div v-if="user.phone" class="border rounded bg-white px-3 py-2">
              {{ user.phone }}
            </div>
            <div v-else class="border rounded bg-white px-3 py-2">-</div>
          </div>

          <div class="col">
            <h6>{{ $t("whatsapp") }}</h6>
            <div
              v-if="user.whatsapp_number"
              class="border rounded bg-white px-3 py-2"
            >
              {{ user.whatsapp_number }}
            </div>
            <div v-else class="border rounded bg-white px-3 py-2">-</div>
          </div>
        </div>

        <div class="row mx-5 my-2">
          <div class="col">
            <h6>{{ $t("email") }}</h6>
            <div class="border rounded bg-white px-3 py-2">
              {{ user.email }}
            </div>
          </div>
        </div>

        <div class="row mx-5 mt-3 d-flex justify-content-end">
          <button
            type="button"
            class="mt-2 button-danger float-center"
            data-toggle="modal"
            data-target="#deleteUser"
          >
            {{ $t("delete") }}
          </button>
          <a
            :href="'/users/' + user.id + '/edit'"
            class="mt-2 ml-2 button-primary float-center"
            >{{ $t("edit") }}</a
          >
        </div>
      </div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="deleteUser"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteUserLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteUserLabel">
                {{ $t("settingsViewPage.warning") }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ $t("confirmDelete", { val: "this user" }) }}?
            </div>
            <div class="modal-footer">
              <button
                id="deleteUserClose"
                type="button"
                class="button-light"
                data-dismiss="modal"
              >
                {{ $t("cancel") }}
              </button>
              <button
                type="button"
                class="button-danger"
                v-on:click="deleteUser()"
              >
                {{ $t("delete") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UsersService from "../../services/UsersService";
import GroupService from "../../services/GroupService";
import UserService from "../../services/UserService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const usersService = new UsersService();
const groupService = new GroupService();
const userService = new UserService();

export default {
  name: "UsersView",
  components: { LoadingPage },
  data: () => {
    return {
      loaded: true,
      user: {},
      groups: [],
      logged_in_user: userService.getLoggedInUser(),
    };
  },
  beforeMount() {
    usersService.getUser(this.$route.params.id).then((response) => {
      this.user = response.data.data;
      groupService.getGroups().then((response) => {
        this.groups = response.data.data;
        this.loaded = false;
      });
    });
  },
  methods: {
    blockUser() {
      this.user.is_blocked = true;
      usersService.updateUser(this.$route.params.id, this.user);
    },

    unblockUser() {
      this.user.is_blocked = false;
      usersService.updateUser(this.$route.params.id, this.user);
    },

    deleteUser() {
      usersService.deleteUser(this.$route.params.id).then(() => {
        document.getElementById("deleteUserClose").click();
        //$('#deleteUser').modal('hide');
        this.$router.push("/users/list");
      });
    },
  },
};
</script>
